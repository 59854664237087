import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AvatarService {
	constructor() {}

	getUserAvatar(imagePath: string): string {
		return `${environment.avatarsUrl}Property_1=${imagePath}`;
	}
}
