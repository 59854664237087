import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class KorviuNotificationService {
	constructor(
		private _nzNotificationService: NzNotificationService,
		private _translateService: TranslateService
	) {}

	public showLocalizedError(errorCode: string): void {
		this._translateService.get(`errors.${errorCode}`).subscribe((message: string) => {
			this.showError(message);
		});
	}

	public showError(message: string): void {
		this._nzNotificationService.create('error', 'Error', message, {
			nzStyle: {
				width: '250px',
				'border-radius': '10px'
			}
		});
	}
}
