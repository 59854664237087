import { Injectable } from '@angular/core';
import { ISignUpInfo } from '../../shared/types/sign-up-info.interface';
import { BaseApiService } from '../../shared/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { KorviuNotificationService } from '../../shared/services/korviu-notification.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NetworkError } from '../../shared/models/network-error';
import { TranslateService } from '@ngx-translate/core';
import { UserTypes } from '../../shared/types/user.model';

@Injectable({
	providedIn: 'root'
})
export class SignUpService extends BaseApiService {
	private _signUpInfo: ISignUpInfo;
	public get signUpInfo(): ISignUpInfo {
		return this._signUpInfo;
	}
	public set signUpInfo(value: ISignUpInfo) {
		this._signUpInfo = value;
	}

	private _step: number;
	public get step(): number {
		return this._step;
	}
	public set step(value: number) {
		this._step = value;
	}

	private _newUserReferralCode: string;
	public get newUserReferralCode(): string {
		return this._newUserReferralCode;
	}
	public set newUserReferralCode(value: string) {
		this._newUserReferralCode = value;
	}

	private _invitationId: number;
	public get invitationId(): number {
		return this._invitationId;
	}
	public set invitationId(value: number) {
		this._invitationId = value;
	}

	private _userTypeCode: UserTypes;
	public get userTypeCode(): UserTypes {
		return this._userTypeCode;
	}
	public set userTypeCode(value: UserTypes) {
		this._userTypeCode = value;
	}

	constructor(
		httpClient: HttpClient,
		korviuNotificationService: KorviuNotificationService,
		translateService: TranslateService
	) {
		super(httpClient, korviuNotificationService, translateService);
		this.initializeSignUp();
	}

	public verifyReferalCode(referral_code: string): Observable<string> {
		return this.post('users/verify-referral-code/', { referral_code }).pipe(
			catchError((error: NetworkError) => this.handleError(error, [226, 403, 410]))
		);
	}

	public initializeSignUp(): void {
		this.step = 0;
		this.signUpInfo = {
			user_info: {
				address: '',
				city: '',
				country_id: '',
				email: '',
				first_name: '',
				last_name: '',
				mission_statement: '',
				name: '',
				password: '',
				phone_number: '',
				phone_number_prefix_country_id: '',
				preferred_method_of_contact_id: '',
				state_id: '',
				website: '',
				zip: ''
			},
			contact_info: {
				terms_and_conditions_accepted: false,
				primary_contact_name: '',
				primary_contact_phone_number: '',
				primary_contact_email: '',
				join_reason: '',
				primary_contact_phone_number_prefix_country_id: 43
			}
		};
	}

	public resetSignUp(): void {
		this.initializeSignUp();
	}
}
