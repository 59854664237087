import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarService } from './services/avatar.service';
import { ThousandsSeparatorPipe } from './pipes/thousands-separator.pipe';
import { DisabledDirective } from './directives/disabled.directive';

@NgModule({
	declarations: [ThousandsSeparatorPipe],
	imports: [CommonModule, DisabledDirective],
	exports: [ThousandsSeparatorPipe],
	providers: [AvatarService]
})
export class SharedModule {}
