import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'thousandsSeparator'
})
export class ThousandsSeparatorPipe implements PipeTransform {
	transform(value: number | string, formatAsDecimal: boolean = false): string {
		let numericValue: number;

		if (typeof value === 'number') {
			numericValue = value;
		} else if (typeof value === 'string' && !isNaN(Number(value))) {
			numericValue = Number(value);
		} else {
			return value;
		}

		if (formatAsDecimal) {
			return new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}).format(numericValue);
		} else {
			return Math.trunc(numericValue).toLocaleString();
		}
	}
}
