import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appDisabled]',
	standalone: true
})
export class DisabledDirective {
	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		event.preventDefault();
	}
}
